import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./projectsPage.module.css";
import Rectangle from "../../assets/WARWICK HOTEL 2.jpg";
import Rectangle4 from "../../assets/WARWICK HOTEL 1.jpg";
import Rectangle1 from "../../assets/RADISSON.jpg";
import Rectangle2 from "../../assets/HILTON DOUBLE TREE HOTEL.jpg";
import Rectangle3 from "../../assets/GRANADA HOTEL 1.jpg";
import Rectangle5 from "../../assets/GRANADA HOTEL 2.jpg";
import { Element } from "react-scroll";
import { useLocation } from "react-router-dom";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./servicesSlide.css";

const Hotels = () => {
  const location = useLocation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    console.log(location);
    if (location.pathname) {
      let elem = document.getElementById(location.pathname);
      if (elem) {
        // console.log(location.pathname);
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // console.log(location.pathname);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
    // console.log(location.pathname);
  }, [location.pathname]);

  return (
    <Element id="/hotels">
      <Container fluid className={classes.projectPageContainer}>
        <div className={classes.projectPage}>
          <div className={classes.project_conent}>
            <span className={classes.hotelsTitle}>Hotels & Resorts</span>
            <Row>
              <Col md={12} className={classes.col}>
                <ul className={classes.ul}>
                  <li className={classes.liFirst}>
                    GRANADA HOTEL, RIYADH - KSA, 2018
                  </li>
                  <li className={classes.liFirst}>
                    WARWICK HOTEL, RIYADH - KSA, 2016
                  </li>
                  <li className={classes.liFirst}>
                    RADISSON BLU RESORT, JIZAN - KSA, 2013
                  </li>
                  <li className={classes.liFirst}>
                    HILTON DOUBLE TREE HOTEL, RIYADH - KSA, 2013
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li>GRANADA HOTEL</li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    RIYADH - KSA
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2018</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <Slider {...settings}>
                  <img src={Rectangle3} alt="" />
                  <img src={Rectangle5} alt="" />
                </Slider>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <Slider {...settings}>
                  <img src={Rectangle} alt="" />
                  <img src={Rectangle4} alt="" />
                </Slider>
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>
                    WARWICK <span>HOTEL</span>
                  </li>
                  <li>
                    RIYADH - <span>KSA</span>
                  </li>
                  <li>2016</li>
                </ul>
              </Col>
            </Row>
          </div>

          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li>
                    RADISSON BLU <span>RESORT</span>
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    JIZAN - <span>KSA</span>
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2013</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <img src={Rectangle1} alt="" />
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <img src={Rectangle2} alt="" />
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>HILTON DOUBLE TREE HOTEL</li>
                  <li>RIYADH - KSA</li>
                  <li>2013</li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Element>
  );
};

export default Hotels;
