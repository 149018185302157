import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./Services.module.css";
import { MdArrowRight } from "react-icons/md";
// icons
import logo1 from "../../assets/Icons/noun-electrical-2958711.svg";
import logo2 from "../../assets/Icons/noun-mechanical-2258582.svg";
import logo3 from "../../assets/Icons/noun-plumbing-3503103.svg";

const Services = () => {
  return (
    <div className={classes.services}>
      <h1>Our Services</h1>
      <div className={classes.services_conent}>
        <Container>
          <Row className={classes.row}>
            <Col md={3} className={classes.col}>
              <div className={classes.items}>
                <img src={logo2} alt="" />
                <p>Mechanical</p>
                <ul className={classes.services_ul}>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Heating, Ventilation & Air-Conditioning (HVAC)
                  </li>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Firefighting
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3} className={classes.col}>
              <div className={`${classes.items} ${classes.itemsShadow}`}>
                <img src={logo1} alt="" />
                <p>Electrical</p>
                <ul className={classes.services_ul}>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Medium Voltage
                  </li>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Low Voltage
                  </li>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Lighting
                  </li>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Telecommunications & Security systems
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={3} className={classes.col}>
              <div className={classes.items}>
                <img src={logo3} alt="" />
                <p>Plumbing</p>
                <ul className={classes.services_ul}>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Water Supply
                  </li>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Drainage
                  </li>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Irrigation
                  </li>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Pools & fountains
                  </li>
                  <li>
                    <MdArrowRight className={classes.icon} />
                    Water Treatment Plants
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Services;
