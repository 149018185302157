import React, { createContext, ReactNode, useEffect, useState } from "react";

// Define the data type

interface IServices {
  contant: { active: boolean };
  visitor: { active: boolean };
}

// Create the context
export const ServiceContext = createContext<IServices | undefined>(undefined);

// Provider component
const ServicesProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<IServices | undefined>(undefined);
  useEffect(() => {
    fetch("https://py.tws.teqneia.com/identity/services/4/")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data: IServices) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  return (
    <ServiceContext.Provider value={data}>{children}</ServiceContext.Provider>
  );
};
export default ServicesProvider;
