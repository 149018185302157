import React, { useContext } from "react";
import { useRegisterVisitor } from "../hooks/useRegisterVisitor.ts";
import { ServiceContext } from "./ServiceProvider.tsx";

const ClientWrapper = ({ children }: { children: React.ReactNode }) => {
  const services = useContext(ServiceContext);
  useRegisterVisitor(!!services?.visitor.active);
  return <>{children}</>;
};

export default ClientWrapper;
