import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./projectsPage.module.css";
import Rectangle from "../../assets/AVENUE PROJECT, AVENUE MALL.jpg";
import Rectangle1 from "../../assets/MARFA MALL (OTHAIM).jpg";
import Rectangle2 from "../../assets/OTHAIM MALL ONAIZAH, ALQASIM 1.jpg";
import Rectangle7 from "../../assets/OTHAIM MALL ONAIZAH, ALQASIM 2.jpg";
import Rectangle3 from "../../assets/OTHAIM MALL- HAFR ALBATEN 2.jpg";
import Rectangle5 from "../../assets/OTHAIM MALL- HAFR ALBATEN 1.jpg";
import Rectangle4 from "../../assets/OTHAIM MALL- KHAFJI.jpg";
import { useLocation } from "react-router-dom";
import { Element } from "react-scroll";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./servicesSlide.css";

const Malls = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      let elem = document.getElementById(location.pathname);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location.pathname]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Element id="/malls">
      <Container fluid className={classes.projectPageContainer}>
        <div className={classes.projectPage}>
          <div className={classes.project_conent}>
            <span className={classes.hotelsTitle}>Malls</span>
            <Row>
              <Col md={12} className={classes.col}>
                <ul className={classes.ul}>
                  <li className={classes.liFirst}>
                    AVENUE PROJECT, AVENUE MALL RE-DESIGN & BOQ, WITH SALINI
                    IMPREGILO CORP, RIYADH – KSA, 2017
                  </li>
                  <li className={classes.liFirst}>
                    MARFA MALL (OTHAIM) - JUBAIL - KSA, 2016
                  </li>
                  <li className={classes.liFirst}>
                    OTHAIM MALL, KHAFJI - KSA, 2015
                  </li>
                  <li className={classes.liFirst}>
                    OTHAIM MALL ONAIZAH, ALQASIM - KSA, 2014
                  </li>
                  <li className={classes.liFirst}>
                    OTHAIM MALL, HAFR ALBATEN - KSA, 2014
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <img src={Rectangle} alt="" />
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>
                    AVENUE PROJECT, AVENUE MALL RE-DESIGN & BOQ, WITH SALINI
                    IMPREGILO CORP.
                  </li>
                  <li>RIYADH - KSA</li>
                  <li>2017</li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li style={{ display: "block", textAlign: "right" }}>
                    MARFA MALL OTHAIM
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    JUBAIL - KSA
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2016</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <img src={Rectangle1} alt="" />
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <img src={Rectangle4} alt="" />
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>OTHAIM MALL</li>
                  <li>KHAFJI - KSA</li>
                  <li>2015</li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li style={{ display: "block", textAlign: "right" }}>
                    OTHAIM MALL ONAIZAH
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    ALQASIM - KSA
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2014</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <Slider {...settings}>
                  <img src={Rectangle2} alt="" />
                  <img src={Rectangle7} alt="" />
                </Slider>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <Slider {...settings}>
                  <img src={Rectangle3} alt="" />
                  <img src={Rectangle5} alt="" />
                </Slider>
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>OTHAIM MALL</li>
                  <li>HAFR ALBATEN - KSA</li>
                  <li>2014</li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Element>
  );
};

export default Malls;
