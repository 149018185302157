import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import classes from "./Projects.module.css";

import Rectangle3 from "../../assets/GRANADA HOTEL 1.jpg";
import Rectangle2 from "../../assets/NAYEF ALATHEL 1.jpg";
import Rectangle1 from "../../assets/MARFA MALL (OTHAIM)-min.jpg";
import Rectangle4 from "../../assets/AL-MOAJEL HEADQUARTER 1.jpg";

import { Element } from "react-scroll";
import { useLocation } from "react-router-dom";

const Projects = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  // animation function
  const [animation, setAnimation] = useState(window.scrollY);
  const breakpoint = 900;

  useEffect(() => {
    window.addEventListener("scroll", () => setAnimation(window.scrollY));
  }, [animation]);

  return (
    <Element id="Projects">
      <div className={classes.projects}>
        <h1>Our Projects</h1>
        <Container>
          <div
            className={`${classes.project_conent} ${
              animation > breakpoint && classes.serDiv_BackGround
            }`}
          >
            <Row>
              <Col md={3} className={classes.col}>
                <div className={classes.containerImage}>
                  <Link to="/hotels">
                    <img src={Rectangle3} alt="Rectangle" />
                  </Link>
                </div>
                <span>HOTELS & RESORTS</span>
              </Col>
              <Col md={3} className={classes.col}>
                <div className={classes.containerImage}>
                  <Link to="/palaces">
                    <img src={Rectangle2} alt="Rectangle" />
                  </Link>
                </div>
                <span>PALACES & COMPOUNDS</span>
              </Col>
              <Col md={3} className={classes.col}>
                <div className={classes.containerImage}>
                  <Link to="/malls">
                    <img src={Rectangle1} alt="Rectangle" />
                  </Link>
                </div>
                <span>MALLS</span>
              </Col>
              <Col md={3} className={classes.col}>
                <div className={classes.containerImage}>
                  <Link to="/others">
                    <img src={Rectangle4} alt="Rectangle" />
                  </Link>
                </div>
                <span>OTHERS</span>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Element>
  );
};

export default Projects;
