import React from "react";
import videoBg from "../../assets/compressed.mp4";
import "./headerVideo.css";

const HeaderVideo = () => {
  return (
    <div className="main1">
      <video autoPlay loop muted width="1000">
        <source src={videoBg} type="video/mp4"></source>
      </video>
    </div>
  );
};

export default HeaderVideo;
