import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./Contact.module.css";

// Icons
import { SiGmail } from "react-icons/si";
import { FaMobile } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";

// import emailjs from "emailjs-com";

import { Element } from "react-scroll";
import { useLocation } from "react-router-dom";

import toast from "react-hot-toast";
import { useState } from "react";

const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const sendEmail = async (ev) => {
    ev.preventDefault();
    const formData = new FormData();
    const url = new URL("https://py.tws.teqneia.com/contacts/?tenant_id=5");

    try {
      const ipResp = await fetch("https://api.ipify.org?format=json");
      const data = await ipResp.json();
      const ip = data.ip;

      formData.append("name", name);
      formData.append("phone", phone);
      formData.append("email", Email);
      formData.append("message_body", message);
      formData.append("company_name", company);
      formData.append("ip", ip);

      if (!name || !phone || !Email || !company || !message) {
        toast.error("Please fill all fields.");
        return;
      }

      const response = await fetch(url, { method: "POST", body: formData });
      if (!response.ok) {
        throw new Error("Unable to send.");
      }
      toast.success("Mail sent successfully.");
      setName("");
      setPhone("");
      setEmail("");
      setMessage("");
      setCompany("");
    } catch (error) {
      toast.error("Unable to send.");
    }
  };

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   if (!name || !phone || !Email || !company || !message) {
  //     toast.error("Please Field All Inputs !");
  //     return;
  //   }

  //   emailjs
  //     .sendForm(
  //       "service_yr2xmpi",
  //       "template_ik4258k",
  //       e.target,
  //       "i_aocxF5NNj_6eRA0"
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       toast.success("Your message is sent !");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toast.error("Somthing went wrong !");
  //     });
  // };

  return (
    <Element id="ContactUs">
      <div className={classes.contact}>
        <Container fluid>
          <Row>
            <Col lg={6}>
              <div className={classes.media}>
                <h1>Let's Connect !</h1>
                <div className={classes.contactUs}>
                  <p>
                    <a href="/">
                      <SiGmail className={classes.icons} /> info@samer.design
                    </a>
                  </p>
                  <p>
                    <FaMobile className={classes.icons} /> +966564977861
                  </p>
                  <p>
                    <FaMobile className={classes.icons} /> +201014523468
                  </p>
                  <p>
                    <FaMapMarkerAlt className={classes.icons} /> 5 Al-Basher St,
                    Al-Khalafawy, Al-Sahel, Cairo
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className={classes.form}>
                <form onSubmit={sendEmail}>
                  <div className={classes.namePhoneContainer}>
                    <div className={classes.name}>
                      <label htmlFor="Name">Name</label>
                      <input
                        type="text"
                        name="name"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.phone}>
                      <label htmlFor="phone">Phone Number</label>
                      <input
                        type="number"
                        name="number"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.email}>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className={classes.email}>
                    <label htmlFor="text">Company</label>
                    <input
                      type="text"
                      name="company"
                      onChange={(e) => {
                        setCompany(e.target.value);
                      }}
                    />
                  </div>
                  <div className={classes.message}>
                    <label htmlFor="message">Message</label>
                    <textarea
                      name="message"
                      id=""
                      cols="30"
                      rows="10"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  <button className={classes.requestBtn} type="submit">
                    Contact Us Now !
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Element>
  );
};

export default Contact;
