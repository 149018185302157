import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ValuesMobile.css";

// icons
import logo1 from "../../assets/noun-integrity-3685349.svg";
import logo2 from "../../assets/noun-idea-3913680.svg";
import logo3 from "../../assets/noun-integrity-4636822.svg";
import logo4 from "../../assets/noun-enjoyment-4065784.svg";
import logo5 from "../../assets/noun-empowering-2202388.svg";
import logo6 from "../../assets/noun-social-responsibility-4388343.svg";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <div className="values">
        <h1>Our Values</h1>
        <div className="values_border"></div>
        <Slider className="slider" {...settings}>
          <div>
            <img src={logo1} alt="" />
            <p>Respect and integrity</p>
          </div>
          <div>
            <img src={logo2} alt="" />
            <p>Creativity and innovation</p>
          </div>
          <div>
            <img src={logo3} alt="" />
            <p>Teamwork and trust</p>
          </div>
          <div>
            <img src={logo4} alt="" />
            <p>Professionalism and excellence</p>
          </div>
          <div>
            <img src={logo5} alt="" />
            <p>Care and empowerment to our partners</p>
          </div>
          <div>
            <img src={logo6} alt="" />
            <p>Environmental and social responsibility</p>
          </div>
        </Slider>
      </div>
    );
  }
}
