import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProjectsMobile.css";
import { Link } from "react-router-dom";

// images
import Rectangle3 from "../../assets/GRANADA HOTEL 1.jpg";
import Rectangle2 from "../../assets/NAYEF ALATHEL 1.jpg";
import Rectangle1 from "../../assets/MARFA MALL (OTHAIM).jpg";
import Rectangle4 from "../../assets/AL-MOAJEL HEADQUARTER 1.jpg";
import { useLocation } from "react-router-dom";
import { Element } from "react-scroll";

const SimpleSlider = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Element id="Projects">
      <div className="projects">
        <h1>Our Projects</h1>
        <div className="projects_border"></div>
        <Slider className="sliderProjects" {...settings}>
          <div>
            <Link to="/hotels">
              <img src={Rectangle3} alt="Rectangle" />
            </Link>
            <h3>HOTELS & RESORTS</h3>
          </div>
          <div>
            <Link to="/palaces">
              <img src={Rectangle2} alt="" />
            </Link>
            <h3>PALACES & COMPOUNDS</h3>
          </div>
          <div>
            <Link to="/malls">
              <img src={Rectangle1} alt="" />
            </Link>
            <h3>Malls</h3>
          </div>
          <div>
            <Link to="/others">
              <img src={Rectangle4} alt="" />
            </Link>
            <h3>Others</h3>
          </div>
        </Slider>
      </div>
    </Element>
  );
};

export default SimpleSlider;
