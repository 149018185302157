import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./projectsPage.module.css";
import Rectangle from "../../assets/King Abdullah Financial District, (KAFD 2.14 TOWER).jpg";
import Rectangle1 from "../../assets/HIT & FIT [GYM & FITNESS CENTER FOR LADIES], ALJOUF 2.jpg";
import Rectangle5 from "../../assets/HIT & FIT [GYM & FITNESS CENTER FOR LADIES], ALJOUF 1.jpg";
import Rectangle2 from "../../assets/DQ SPORTS COMPLEX PH2 2.jpg";
import Rectangle6 from "../../assets/DQ SPORTS COMPLEX PH2 1.jpg";
import Rectangle3 from "../../assets/AL-MOAJEL HEADQUARTER 1.jpg";
import Rectangle7 from "../../assets/AL-MOAJEL HEADQUARTER 2.jpg";
import Rectangle4 from "../../assets/MINISTRY OF JUSTICE- TRAINING CENTER.jpg";
import Rectangle8 from "../../assets/NEW YOU MEDICAL CENTER.jpg";
import Rectangle9 from "../../assets/SUSHI-SAMBA FOOD COMPLEX.jpg";

import { useLocation } from "react-router-dom";
import { Element } from "react-scroll";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./servicesSlide.css";

const Others = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      let elem = document.getElementById(location.pathname);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location.pathname]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Element id="/others">
      <Container fluid className={classes.projectPageContainer}>
        <div className={classes.projectPage}>
          <div className={classes.project_conent}>
            <span className={classes.hotelsTitle}>Others </span>
            <Row>
              <Col md={12} className={classes.col}>
                <ul className={classes.ul}>
                  <li className={classes.liFirst}>
                    DQ SPORTS COMPLEX PHASE 2, RIYADH - KSA, 2022
                  </li>
                  <li className={classes.liFirst}>
                    AL-MOAJEL ADMINISTRATION BUILDING (HEADQUARTER), RIYADH -
                    KSA, 2020
                  </li>
                  <li className={classes.liFirst}>
                    KING ABDULLAH FINANCIAL DISTRICT, (KAFD 2.14 TOWER), RIYADH
                    - KSA, 2019
                  </li>
                  <li className={classes.liFirst}>
                    SUSHI-SAMBA FOOD COMPLEX, RIYADH - KSA, 2018
                  </li>
                  <li className={classes.liFirst}>
                    HIT & FIT [GYM & FITNESS CENTER FOR LADIES], ALJOUF - KSA,
                    2017
                  </li>
                  <li className={classes.liFirst}>
                    NEW YOU MEDICAL CENTER, RIYADH - KSA, 2015
                  </li>
                  <li className={classes.liFirst}>
                    MINISTRY OF JUSTICE - TRAINING CENTER, RIYADH - KSA, 2015
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li style={{ display: "block", textAlign: "right" }}>
                    DQ SPORTS COMPLEX PHASE 2
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    RIYADH - KSA
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2022</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <Slider {...settings}>
                  <img src={Rectangle2} alt="" />
                  <img src={Rectangle6} alt="" />
                </Slider>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <Slider {...settings}>
                  <img src={Rectangle3} alt="" />
                  <img src={Rectangle7} alt="" />
                </Slider>
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>AL-MOAJEL ADMINISTRATION BUILDING (HEADQUARTER)</li>
                  <li>ALJOUF - KSA</li>
                  <li>2020</li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li style={{ display: "block", textAlign: "right" }}>
                    KING ABDULLAH FINANCIAL DISTRICT, (KAFD 2.14 TOWER)
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    RIYADH - KSA
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2019</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <img src={Rectangle} alt="" />
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <img src={Rectangle9} alt="" />
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>SUSHI-SAMBA FOOD COMPLEX,</li>
                  <li>RIYADH - KSA</li>
                  <li>2018</li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li style={{ display: "block", textAlign: "right" }}>
                    HIT & FIT [GYM & FITNESS CENTER FOR LADIES]
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    ALJOUF - KSA
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2017</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <Slider {...settings}>
                  <img src={Rectangle1} alt="" />
                  <img src={Rectangle5} alt="" />
                </Slider>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col md={6} className={classes.col}>
                <img src={Rectangle8} alt="" />
              </Col>
              <Col md={6} className={classes.col1} style={{ display: "flex" }}>
                <ul className={classes.ul}>
                  <li>NEW YOU MEDICAL CENTER,</li>
                  <li>RIYADH - KSA</li>
                  <li>2015</li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className={classes.project_content_1}>
            <Row className={classes.row}>
              <Col
                md={6}
                className={classes.col1}
                xs={{ order: 2 }}
                lg={{ order: 1 }}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ul className={classes.ul}>
                  <li style={{ display: "block", textAlign: "right" }}>
                    MINISTRY OF JUSTICE - TRAINING CENTER
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>
                    RIYADH - KSA
                  </li>
                  <li style={{ display: "block", textAlign: "right" }}>2015</li>
                </ul>
              </Col>
              <Col
                md={6}
                className={classes.col}
                xs={{ order: 1 }}
                lg={{ order: 2 }}
              >
                <img src={Rectangle4} alt="" />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </Element>
  );
};

export default Others;
