import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useRegisterVisitor = (isActive: boolean) => {
  const history = useHistory();
  const sourceParam = new URLSearchParams(window.location.search);
  const source = sourceParam.get("s");
  const path = window.location.pathname;

  useEffect(() => {
    if (isActive) {
      fetch("https://api.ipify.org?format=json")
        .then((ipResp) => {
          if (!ipResp.ok) {
            throw new Error("Failed to fetch IP");
          }
          return ipResp.json();
        })
        .then((data) => {
          const ip = data.ip;

          // Construct the FormData
          const formData = new FormData();
          formData.append("page", window.location.hostname);
          formData.append("source_website", document.referrer);
          formData.append("ip", ip);

          // Only append "reference" if source is not null
          if (source !== null) {
            formData.append("reference", source);
          }

          // Send POST request
          return fetch(
            "https://py.tws.teqneia.com/create-report/?tenant_id=4",
            {
              method: "POST",
              body: formData,
            }
          );
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Unable to submit visit data.");
          }

          // Redirect if source exists
          if (source) {
            history.push(path); // Use history.push in v5
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isActive, source, path, history]);
};
